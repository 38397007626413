import { atom, useAtom } from 'jotai';

export type MODAL_VIEW =
  | 'DISCONNECT_WALLET'
  | 'RETIRE_DREP'
  | 'SHARE'
  | 'SEARCH'
  | 'CONNECT_WALLET'
  | 'FEE_INFO'
  | 'REVIEW_YOUR_CHANGES'
  | 'CAST_VOTE';

const modalAtom = atom({ isOpen: false, view: '', modalProps: null });

export function useModal() {
  const [state, setState] = useAtom(modalAtom);
  const openModal = (view: MODAL_VIEW, modalProps: any = null) =>
    setState({
      ...state,
      isOpen: true,
      view,
      modalProps,
    });
  const closeModal = () => {
    setState({ ...state, isOpen: false, modalProps: null, view: '' });
  };

  return {
    ...state,
    openModal,
    closeModal,
  };
}
