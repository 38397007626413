export const umamiNetworks = {
  PREVIEW: 'd1c383fe-8226-4640-a1bf-906b1ee02075',
  PREPROD: '43a5fa1d-01da-4ecf-9d18-af2ece375653',
  MAINNET: '8d968427-3d64-4db1-86c5-3cad6a10785f',
};
const domainMapping = {
  'preview.drep.id': 'PREVIEW',
  'preprod.drep.id': 'PREPROD',
  'drep.id': 'MAINNET',
  'dev.drep.id': 'PREVIEW',
};

const website = (() => {
  if (typeof window === 'undefined') return undefined;
  return window.location.hostname;
})();

const isDev = website && website.includes('dev');

const websiteNetwork = (domainMapping as Record<string, string>)[
  website as string
] as string | undefined;

const networkName = (
  process.env.NEXT_PUBLIC_CARDANO_NETWORK ||
  websiteNetwork ||
  'PREVIEW'
).toUpperCase();

const umamiId =
  process.env.NEXT_PUBLIC_UMAMI_WEBSITE_ID || websiteNetwork
    ? (umamiNetworks as Record<string, string>)[websiteNetwork as string]
    : undefined;

const apmEnv =
  process.env.NEXT_PUBLIC_APM_ENV ||
  process.env.NEXT_PUBLIC_APM_ENVIRONMENT ||
  process.env.ELASTIC_APM_ENVIRONMENT ||
  websiteNetwork
    ? isDev
      ? websiteNetwork + '-dev'
      : websiteNetwork
    : undefined;

const environments = {
  // api host configs
  IS_IN_PRODUCTION_MODE:
    process.env.NEXT_PUBLIC_NODE_ENV === 'production' ||
    process.env.NEXT_PUBLIC_NODE_ENV === 'prod',
  BASE_API_URL: process.env.NEXT_PUBLIC_BASE_API_URL || '/api',
  INTERNAL_API_URL: process.env.INTERNAL_API_URL,
  publicUmamiId: umamiId,
  internalUmamiId: umamiId || ((umamiNetworks as any)[networkName] as string),
  enableUmami:
    !!umamiId || !!website || process.env.NEXT_PUBLIC_UMAMI_ENABLED === 'true',
  enableAPM: !!website || process.env.NEXT_PUBLIC_APM_ENABLED === 'true',
  apmEnvironment: apmEnv,
  METADATA_API_URL: process.env.METADATA_API_URL || 'https://metadata.drep.id',
  NETWORK_ID:
    process.env.NEXT_PUBLIC_NETWORK_ID ||
    (networkName && networkName === 'MAINNET' ? '1' : '0'),
  CARDANO_NETWORK: networkName,
};
// environments.apmEnvironment = (apmEnv  ||  website  || 'local').toLocaleLowerCase()

export default environments;
