import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { IDRepDelegation } from '@src/store/drep/types';
import {
  type DRepMetadata,
  type IDelegateDRepResponse,
  type IDRepDelegators,
  type IDRepRegistration,
  type IDRepStats,
  type IDRepVote,
  type ILoginRequestBody,
  type ILogoutResponse,
  type IMintDRepTokenRequestBody,
  type IMintDRepTokenResponse,
  type ISubmitTxRequestBody,
  type IMintedDrepMetaData,
} from '@src/store/drep/types';
import environments from '@src/configs/environments';
import { bech32ToHex } from '@src/utils/dRepUtils';

export const DREP_PATH = 'drep';

export const drepApi = createApi({
  reducerPath: DREP_PATH,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: environments.BASE_API_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.append('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDRepById: builder.query<DRepMetadata, string>({
      query: (id) => ({
        url: `/drep?id=${id}`,
        method: 'GET',
      }),
    }),
    getDRepByName: builder.query<DRepMetadata, string>({
      query: (name) => ({
        url: `/drep?name=${name}`,
        method: 'GET',
      }),
    }),
    mintDRepToken: builder.mutation<
      IMintDRepTokenResponse,
      IMintDRepTokenRequestBody
    >({
      query: (mintDRepTokenRequestBody) => ({
        url: `/register`,
        method: 'POST',
        body: JSON.stringify(mintDRepTokenRequestBody),
      }),
    }),
    getDelegatedDRep: builder.query<IDelegateDRepResponse | null, string>({
      query: (stakeAddress) => ({
        url: `/vote/delegation?stakeAddress=${stakeAddress}`,
        method: 'GET',
      }),
    }),
    submitDRepToken: builder.mutation<
      IMintDRepTokenResponse,
      ISubmitTxRequestBody
    >({
      query: (submitDrepTokenRequestBody) => ({
        url: `/submit`,
        method: 'POST',
        body: JSON.stringify(submitDrepTokenRequestBody),
      }),
    }),
    dRepUnique: builder.query<boolean, string>({
      query: (name) => ({
        url: `/is-unique?name=${name}`,
        method: 'GET',
      }),
    }),
    dRepLogin: builder.mutation<boolean, ILoginRequestBody>({
      query: (loginRequestBody) => ({
        url: `/login`,
        method: 'POST',
        body: JSON.stringify(loginRequestBody),
      }),
    }),
    dRepLogout: builder.mutation<ILogoutResponse, void>({
      query: () => ({
        url: `/logout`,
        method: 'GET',
      }),
    }),
    getDRepVotes: builder.query<IDRepVote[], string>({
      query: (dRepId) => ({
        url: `/drep/${bech32ToHex(dRepId)}/vote`,
        method: 'GET',
      }),
    }),
    getActiveDRepDelegators: builder.query<IDRepDelegators[], string>({
      query: (dRepId) => ({
        url: `/drep/${bech32ToHex(dRepId)}/delegator`,
        method: 'GET',
      }),
    }),
    getDRepRegistrations: builder.query<IDRepRegistration[], string>({
      query: (dRepId) => ({
        url: `/drep/${bech32ToHex(dRepId)}/registration`,
        method: 'GET',
      }),
    }),
    getDRepStats: builder.query<IDRepStats, string>({
      query: (dRepId) => ({
        url: `/drep/stats?id=${dRepId}`,
        method: 'GET',
      }),
    }),
    getMintedDRepMetadata: builder.query<
      IMintedDrepMetaData[],
      number | undefined
    >({
      query: (limit = 10) => ({
        url: `/drep/accounts?limit=${limit}`,
        method: 'GET',
      }),
    }),
    getDRepDelegations: builder.query<IDRepDelegation[], string>({
      query: (dRepId) => ({
        url: `/drep/${dRepId}/delegation`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetDRepByIdQuery,
  useGetDRepByNameQuery,
  useLazyGetDRepByNameQuery,
  useLazyGetDRepByIdQuery,
  useMintDRepTokenMutation,
  useGetDelegatedDRepQuery,
  useLazyGetDelegatedDRepQuery,
  useSubmitDRepTokenMutation,
  useDRepLoginMutation,
  useDRepLogoutMutation,
  useLazyDRepUniqueQuery,
  useLazyGetDRepVotesQuery,
  useGetDRepVotesQuery,
  useGetActiveDRepDelegatorsQuery,
  useGetDRepRegistrationsQuery,
  useGetDRepStatsQuery,
  useGetMintedDRepMetadataQuery,
  useGetDRepDelegationsQuery,
} = drepApi;
